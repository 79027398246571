<template>
  <div>
    <a-form :label-col="{}" :wrapper-col="{}" class="clientdata">
      <a-row :gutter="48">
        <a-col
          v-for="v in $v.fields.$each.$iter"
          :key="v.$model.id"
          class="form-item"
          :span="12"
        >
          <a-form-item
            :has-feedback="v.value.minLength"
            :validate-status="v.value.$error || v.name.$error ? 'error' : ''"
            :label="v.$model.name"
            :help="
              (v.value.$error && 'Необходимо заполнить поле') ||
              (!v.name.customCheckFields && 'Не правильный ИНН')
            "
          >
            <a-input
              v-model.trim="v.value.$model"
              @input="
                e =>
                  setField({
                    name: v.$model.createclientname,
                    value: e.target.value,
                  })
              "
              :placeholder="v.$model.placeholder"
              :value="checkFields(v.$model.createclientname)"
              v-mask="v.$model.mask"
            />
          </a-form-item>
        </a-col>
        <!--        <a-col :span="12" class="form-item">-->
        <!--          <PaymentGroupNewProvider />-->
        <!--        </a-col>-->
      </a-row>
    </a-form>

    <div
      v-if="this.$route.name !== 'CreateNewProvider' && isDealer"
      class="buttonsholder"
    >
      <div class="flex">
        <a-button type="primary" @click="onSaveClick">Сохранить</a-button>
      </div>
    </div>
  </div>
</template>

<script>
// Юридическое лицо
import { message } from "ant-design-vue"

let filedsSupplierTypeId1 = function () {
  return [
    {
      id: 1,
      name: "Наименование поставщика",
      placeholder: "Введите имя",
      createclientname: "name",
      value: "",
      mask: "",
    },
    {
      id: 4,
      name: "Наименование юридического лица",
      placeholder: "Введите полное название юр. лица",
      createclientname: "legal_name",
      value: "",
      mask: "",
    },
    {
      id: 5,
      name: "Номер телефона",
      placeholder: "Введите номер телефона",
      createclientname: "phone",
      value: "",
      mask: "8##########",
    },
    {
      id: 6,
      name: "ИНН",
      placeholder: "Введите ИНН",
      createclientname: "inn",
      value: "",
      mask: "###############",
    },
    {
      id: 11,
      name: "Должность контактного лица",
      placeholder: "Должность контактного лица",
      createclientname: "head_position",
      value: "",
      mask: "",
    },
    {
      id: 13,
      name: "ФИО контактного лица",
      placeholder: "ФИО контактного лица",
      createclientname: "head_name",
      value: "",
      mask: "",
    },
    {
      id: 14,
      name: "Юридический адрес",
      placeholder: "Юридический адрес",
      createclientname: "legal_address",
      value: "",
      mask: "",
    },
    {
      id: 16,
      name: "Фактический адрес",
      placeholder: "Фактический адрес",
      createclientname: "physical_address",
      value: "",
      mask: "",
    },
  ]
}

/*
// Физическое лицо
let filedssupplierTypeId2 = function() {
  return [
    {
      id: 1,
      name: "Имя",
      placeholder: "Введите имя",
      createclientname: "firstName",
      value: "",
      mask: "",
    },
    {
      id: 2,
      name: "Фамилия",
      placeholder: "Введите фамилию",
      createclientname: "lastName",
      value: "",
      mask: "",
    },
    {
      id: 3,
      name: "Отчество",
      placeholder: "Введите отчество",
      createclientname: "middleName",
      value: "",
      mask: "",
    },

    {
      id: 4,
      name: "Дата рождения",
      placeholder: "Введите дату рождения в формате 1990-01-01",
      createclientname: "birthDate",
      value: "",
      mask: "####-##-##",
    },

    {
      id: 5,
      name: "Номер телефона",
      placeholder: "Введите номер телефона",
      createclientname: "phone",
      value: "",
      mask: "8##########",
    },

    {
      id: 6,
      name: "Время доставки до ДЦ",
      placeholder: "Введите доставки до ДЦ",
      createclientname: "deliveryPeriod",
      value: "",
      mask: "####",
    },
    {
      id: 7,
      name: "ИНН",
      placeholder: "Введите ИНН",
      createclientname: "legalInfoInn",
      value: "",
      mask: "###############",
    },
  ]
}
*/

function getFieldsBySupplierTypeId(typeId) {
  let result = {}
  switch (typeId) {
    case 1:
      result = filedsSupplierTypeId1()
      break
    // case "2":
    //   result = filedsSupplierTypeId2()
    //   break
    default:
      result = filedsSupplierTypeId1()
      break
  }
  return result
}

import { mapMutations, mapGetters, mapActions } from "vuex"
import { required } from "vuelidate/lib/validators"
//import ButtonsHolderUpdateClient from "@/components/clients/ButtonsHolderUpdateClient"
//import PaymentGroupNewProvider from "@/components/provider/PaymentGroupNewProvider"
// eslint-disable-next-line no-unused-vars
let fieldsExt
// eslint-disable-next-line no-unused-vars
const customCheckFields = function (value) {
  /*
  if (value == "ИНН") {
    let e = /^(\d{10}|\d{12})$/
    return e.test(fieldsExt.find(el => el.name == value).value)
  } else return true
  */
  return true
}
export default {
  name: "ProviderData",
  components: {
    //ButtonsHolderUpdateClient,
    //PaymentGroupNewProvider,
  },
  computed: {
    ...mapGetters({
      createForm: "suppliers/createForm",
      isDealer: "auth/isDealer",
    }),
  },
  data: () => ({
    supplierTypeId: 1,
    fields: [],
  }),
  validations: {
    fields: {
      required,
      $each: {
        value: {
          required,
        },
        name: {
          customCheckFields,
        },
      },
    },
  },
  watch: {
    createForm() {
      this.getFormFields()
    },
    supplierTypeId() {
      this.fields = this.getClearFields()
    },
  },
  mounted() {
    if (this.$route.name === "CreateNewProvider") {
      this.fields = this.getClearFields()
    } else {
      this.fields = this.getFormFields()
    }

    fieldsExt = this.fields
  },
  methods: {
    ...mapMutations({
      setField: "suppliers/SET_CREATE_FORM_FIELD",
    }),
    ...mapActions({
      updateSupplier: "suppliers/updateSupplier",
    }),
    async onSaveClick() {
      await this.updateSupplier()
      message.success("Данные успешно сохранены!")
    },

    checkClient() {
      this.$v.fields.$touch()
    },
    getClearFields() {
      return getFieldsBySupplierTypeId(this.supplierTypeId)
    },
    getFormFields() {
      this.fields = getFieldsBySupplierTypeId(this.supplierTypeId)
      return this.fields.map(item => {
        const createFormValue = this.createForm[item.createclientname]
        if (createFormValue) {
          item.value = createFormValue
        }
        return item
      })
    },
    checkFields(e) {
      // TODO: Переделать
      if (e === "name") {
        return this.createForm.name
      } else if (e === "legalInfoPaymentAccount") {
        return this.createForm.legalInfoPaymentAccount
      } else if (e === "legalInfoName") {
        return this.createForm.legalInfoName
      } else if (e === "legalInfoBankId") {
        return this.createForm.legalInfoBankId
      } else if (e === "legalInfoInn") {
        return this.createForm.legalInfoInn
      } else if (e === "legalInfoBankName") {
        return this.createForm.legalInfoBankName
      } else if (e === "legalInfoKpp") {
        return this.createForm.legalInfoKpp
      } else if (e === "legalInfoCorrespondedAccount") {
        return this.createForm.legalInfoCorrespondedAccount
      } else if (e === "legalInfoOgrn") {
        return this.createForm.legalInfoOgrn
      } else if (e === "headPosition") {
        return this.createForm.headPosition
      } else if (e === "legalInfoOkpo") {
        return this.createForm.legalInfoOkpo
      } else if (e === "headName") {
        return this.createForm.headName
      } else if (e === "legalInfoAddress") {
        return this.createForm.legalInfoAddress
      } else if (e === "legalInfoUnderlyingDocument") {
        return this.createForm.legalInfoUnderlyingDocument
      } else if (e === "legalInfoPhysicalAddress") {
        return this.createForm.legalInfoPhysicalAddress
      }
    },
    handleAcrtionProviderData() {
      this.checkClient()
      if (this.$v.$error) {
        window.scrollTo(0, "100%")
      }
    },
  },
}
</script>

<style lang="scss">
.buttonsholder {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0 auto;
}
.clientdata {
  label {
    font-size: 13px !important;
    color: rgba(black, 0.7);
  }
}
</style>
